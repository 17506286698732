import React from 'react';

import Head from 'next/head';

import { useRouter } from 'next/router';

import {
  Box,
  CardActionArea,
  Container,
  Grid,
  Typography
} from '@mui/material';

import AuthDialog from '../components/auth/AuthDialog';
import MBLogo from '../components/MBLogo';
import { NoAuthRoute } from '../contexts/auth';
import {NextLinkComposed} from '../components/Link';


const Login = () => {
  
  const router = useRouter();

  const handleLogin = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;

    if (router.query['next']) {
      router.push(router.query['next']);
    } else {
      router.push('/');
    }
  };

  return (
    <>
      <Head>
        <title>Login | MechanicBuddy</title>
        <meta name="description" content="Sign in and keep your car in top shape." />
      </Head>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          bgcolor: 'primary.main',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: 'url("/img/home-hero-x2.jpg")',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box width="100%" py={8}>
          <Container maxWidth="md">
            <Grid item xs={12} md={6} align="center">
              <CardActionArea
                component={NextLinkComposed}
                to={{ pathname: '/' }}
                sx={{
                  '& svg': {
                    width: '450px',
                    height: '100%',
                  },
                }}
              >
                <MBLogo />
              </CardActionArea>
              <Box color="white">
                <Typography variant="h4" component="h4" gutterBottom>
                  Car care made easier
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}></Grid>
            <AuthDialog
              open={true}
              isPage={true}
              handleClose={handleLogin}
              dialogBD={true}
              hideLogo={true}
            />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default NoAuthRoute(Login);
